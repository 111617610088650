import React from 'react';

const GlobeIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      className={className}
    >
      <rect width="19" height="18" fill="black" />
      <path
        d="M-1293 -3209C-1293 -3210.1 -1292.1 -3211 -1291 -3211H9597C9598.1 -3211 9599 -3210.1 9599 -3209V13544C9599 13545.1 9598.1 13546 9597 13546H-1291C-1292.1 13546 -1293 13545.1 -1293 13544V-3209Z"
        fill="white"
      />
      <path
        d="M-944 -741C-944 -742.105 -943.105 -743 -942 -743H8643C8644.1 -743 8645 -742.105 8645 -741V2294C8645 2295.1 8644.1 2296 8643 2296H-942C-943.105 2296 -944 2295.1 -944 2294V-741Z"
        fill="#EEEEEE"
      />
      <g clipPath="url(#clip0_6315_18316)">
        <rect
          width="394"
          height="1465"
          transform="translate(-127 -464)"
          fill="white"
        />
        <g filter="url(#filter0_b_6315_18316)">
          <rect
            x="-109"
            y="-17"
            width="358"
            height="116"
            rx="16"
            fill="url(#paint0_linear_6315_18316)"
            fillOpacity="0.16"
          />
          <g clipPath="url(#clip1_6315_18316)">
            <path
              d="M1.54883 8.11111H3.27778C4.25962 8.11111 5.05556 8.90705 5.05556 9.88889V10.7778C5.05556 11.7596 5.85149 12.5556 6.83333 12.5556C7.81517 12.5556 8.61111 13.3515 8.61111 14.3333V16.9512M5.94444 1.83157V3.22222C5.94444 4.44952 6.93937 5.44444 8.16667 5.44444H8.61111C9.59295 5.44444 10.3889 6.24038 10.3889 7.22222C10.3889 8.20406 11.1848 9 12.1667 9C13.1485 9 13.9444 8.20406 13.9444 7.22222C13.9444 6.24038 14.7404 5.44444 15.7222 5.44444L16.6684 5.44444M12.1667 16.5448V14.3333C12.1667 13.3515 12.9626 12.5556 13.9444 12.5556H16.6684M17.5 9C17.5 13.4183 13.9183 17 9.5 17C5.08172 17 1.5 13.4183 1.5 9C1.5 4.58172 5.08172 1 9.5 1C13.9183 1 17.5 4.58172 17.5 9Z"
              stroke="#111827"
              strokeWidth="1.33"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
      <path
        d="M-942 -742H8643V-744H-942V-742ZM8644 -741V2294H8646V-741H8644ZM8643 2295H-942V2297H8643V2295ZM-943 2294V-741H-945V2294H-943ZM-942 2295C-942.552 2295 -943 2294.55 -943 2294H-945C-945 2295.66 -943.657 2297 -942 2297V2295ZM8644 2294C8644 2294.55 8643.55 2295 8643 2295V2297C8644.66 2297 8646 2295.66 8646 2294H8644ZM8643 -742C8643.55 -742 8644 -741.552 8644 -741H8646C8646 -742.657 8644.66 -744 8643 -744V-742ZM-942 -744C-943.657 -744 -945 -742.657 -945 -741H-943C-943 -741.552 -942.552 -742 -942 -742V-744Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M-1291 -3210H9597V-3212H-1291V-3210ZM9598 -3209V13544H9600V-3209H9598ZM9597 13545H-1291V13547H9597V13545ZM-1292 13544V-3209H-1294V13544H-1292ZM-1291 13545C-1291.55 13545 -1292 13544.6 -1292 13544H-1294C-1294 13545.7 -1292.66 13547 -1291 13547V13545ZM9598 13544C9598 13544.6 9597.55 13545 9597 13545V13547C9598.66 13547 9600 13545.7 9600 13544H9598ZM9597 -3210C9597.55 -3210 9598 -3209.55 9598 -3209H9600C9600 -3210.66 9598.66 -3212 9597 -3212V-3210ZM-1291 -3212C-1292.66 -3212 -1294 -3210.66 -1294 -3209H-1292C-1292 -3209.55 -1291.55 -3210 -1291 -3210V-3212Z"
        fill="white"
        fillOpacity="0.1"
      />
      <defs>
        <filter
          id="filter0_b_6315_18316"
          x="-143"
          y="-51"
          width="426"
          height="184"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="17" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_6315_18316"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_6315_18316"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_6315_18316"
          x1="-77.7265"
          y1="-13.1967"
          x2="-82.6818"
          y2="98.7807"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#414A55" stopOpacity="0.6" />
          <stop offset="1" stopColor="#D3C1FA" stopOpacity="0.6" />
        </linearGradient>
        <clipPath id="clip0_6315_18316">
          <rect
            width="394"
            height="1465"
            fill="white"
            transform="translate(-127 -464)"
          />
        </clipPath>
        <clipPath id="clip1_6315_18316">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GlobeIcon;
